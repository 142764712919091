<template>
  <div>
    <Modal>
      <div class="title">{{ !catalogo.id ? 'Nuevo' : 'Editar' }} catálogo</div>
      <div class="body">
        <div class="row form-group">
          <label for="nombre" class="col-form-label col-sm-3">Nombre</label>
          <div class="col-sm-9"><input v-model="nuevo_catalogo.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
        </div>

        <div class="row form-group">
          <label for="codigo" class="col-form-label col-sm-3">Código</label>
          <div class="col-sm-9"><input v-model="nuevo_catalogo.codigo" type="text" name="codigo" id="codigo" class="form-control"></div>
        </div>

        <div class="row form-group">
          <label for="estado" class="col-form-label col-sm-3">Estado</label>
          <div class="col-sm-9">
            <select v-model="nuevo_catalogo.estatus" name="estado" id="estado" class="form-control">
              <option value="1">Activo</option>
              <option value="0">Inactivo</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 text-right"><button class="btn btn-secondary" @click="modal_opcion=true">Agregar opción</button></div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <DataGrid :configuracion="config_datagrid" :data="nuevo_catalogo.opciones" @ready="buscar_botones" />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="guardar_catalogo">Guardar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>

    <Opcion v-if="modal_opcion" :opcion="opcion" @add="agregar_opcion" @close="cancelar_opcion" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  import DataGrid from '@/components/DataGridV3'

  import Opcion from './Opcion'

  import apiCatalogo from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    components: {
      Modal, DataGrid, Opcion
    }
    ,props: {
      catalogo: {
        type: Object
        ,default() {
          return {
            id: null
            ,nombre: null
            ,codigo: null
            ,estatus: 1
            ,opciones: []
          }
        }
      }
    }
    ,data() {
      return {
        config_datagrid: {
          selector: false
          ,show_search: false
          ,show_info: false
          ,cols: {
            nombre: 'Nombre'
            ,valor: 'Valor'
            ,estatus: 'Estatus'
            ,created_at: 'Opciones'
          }
          ,mutators: {
            estatus(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            }
            ,created_at(val,row,vue) {
              return '<div class="text-center"><img class="mr-2 btn-opciones" data-action="editar" data-opcion="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar opción" title="Editar opción" />'
                  +  '<img class="btn-opciones" data-action="eliminar" data-opcion="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar opción" title="Elimitar opción" /></div>';
            }
          }
        }
        ,nuevo_catalogo: {
          nombre: null
          ,codigo: null
          ,estado: 1
          ,opciones: []
        }
        ,modal_opcion: false
        ,opcion: {
          id: null
          ,nombre: null
          ,valor: null
          ,estatus: 1
        }
      }
    }
    ,mounted() {
      if (this.catalogo.id)
        this.obtener_catalogo();
    }
    ,methods: {
      async obtener_catalogo() {
        try {
          this.nuevo_catalogo = (await apiCatalogo.obtener_catalogo(this.catalogo.id)).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,agregar_opcion(opcion) {
        let opciones = this.$helper.clone(this.nuevo_catalogo.opciones);
        this.nuevo_catalogo.opciones = [];

        if (typeof opcion.index == 'undefined' && !opcion.id) {
          opcion.index = (opciones.lenght - 1);
          opciones.push(opcion);
        }else {
          for(let i=0; i<opciones.length; i++) {
            if (opcion.id) {
              if (opciones[i].id == opcion.id)
                opciones[i] = opcion
            }else if (opcion.index) {
              if (i == opcion.index)
                opciones[i] = opcion;
            }
          }
        }

        this.nuevo_catalogo.opciones = opciones;

        this.cancelar_opcion();
      }
      ,cancelar_opcion() {
        this.opcion = {
          id: null
          ,nombre: null
          ,valor: null
          ,estatus: 1
        }
        this.modal_opcion = false;
      }
      ,async guardar_catalogo() {
        try {
          if (!this.nuevo_catalogo.nombre)
            return this.$helper.showMessage('Error','Debes definir el nombre del catálogo','error','alert');
          
          if (!this.nuevo_catalogo.codigo)
            return this.$helper.showMessage('Error','Debes definir el código del catálogo','error','alert');
          
          if (this.nuevo_catalogo.opciones.length == 0)
            return this.$helper.showMessage('Error','Debes agregar al menos una opción','error','alert');

          let catalogo = this.nuevo_catalogo;
          catalogo = this.$helper.clear_null_nodes(catalogo, true);
          this.$log.info('catalogo',catalogo);

          let res;
          if (!this.nuevo_catalogo.id)
            res = (await apiCatalogo.crear_catalogo(catalogo)).data;
          else
            res = (await apiCatalogo.editar_catalogo(this.nuevo_catalogo.id, catalogo)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,buscar_botones() {
        let btns = document.querySelectorAll('.btn-opciones');
        
        btns.forEach(btn => {
          btn.removeEventListener('click', (e) => {
            this.ejecutar_accion(e);
          }, false);

          btn.addEventListener('click', (e) => {
            this.ejecutar_accion(e);
          }, false);
        })
      }
      ,ejecutar_accion(e) {
        let opcion = this.$helper.fromJson(e.target.dataset.opcion);
        this.$log.info('opcion', opcion);
        let accion = e.target.dataset.action;
        
        
        if (accion == 'editar') {
          this.opcion = opcion;
          this.modal_opcion = true;
        }else {
          let opciones = [];
          this.nuevo_catalogo.opciones.forEach(tmp_opcion => {
            if (opcion.id) {
              if (tmp_opcion.id != opcion.id) {
                opciones.push(tmp_opcion);
              }
            }else {
              if (tmp_opcion.index != opcion.index) {
                opciones.push(tmp_opcion);
              }
            }
          });

          this.nuevo_catalogo.opciones = opciones;
        }
      }
    }
  }
</script>